import React from 'react'
import  Typography from '@mui/material/Typography'
import  Stack from '@mui/material/Stack'

type SectionTitleProps = {
    title: string;
}

export const SectionTitle : React.FC<SectionTitleProps> = ({title}) => {
  return (
    <Stack sx={{paddingY: 2}}>
        <Typography variant='h5'>{title}</Typography>
    </Stack>
  )
}
