import { useApi } from "@backstage/core-plugin-api";
import { spocApiRef } from "../../../../../api";

export const useSPOCHealthChecks = () => {
  const spocApi = useApi(spocApiRef);

  return {
    // Models Checks
    gpt35: () =>
      new Promise<boolean>(async (resolve) => {
        try {
          const response = await spocApi.testGPT35();

          resolve(response && typeof response === 'string' && response.includes('hi'));
        } catch (e) {
          resolve(false);
        }
      }),

    // Contentful Checks
    contentfulAnnouncements: () =>
      new Promise<boolean>(async (resolve) => {
        try {
          const announcementsResult = await spocApi.getContentfulAnnouncements(
            ""
          );

          const isArray = Array.isArray(announcementsResult);

          resolve(isArray);
        } catch (e) {
          resolve(false);
        }
      }),
    outageBanner: () =>
      new Promise<boolean>(async (resolve) => {
        try {
          const announcementsResult = await spocApi.getContentfulOutAgeMessage(
            ""
          );
          const isArray = Array.isArray(announcementsResult);

          resolve(isArray);
        } catch (e) {
          resolve(false);
        }
      }),
    interruptBanner: () =>
      new Promise<boolean>(async (resolve) => {
        try {
          const announcementsResult =
            await spocApi.getContentfulInterruptAnnouncement("");
          const isArray = Array.isArray(announcementsResult);

          resolve(isArray);
        } catch (e) {
          resolve(false);
        }
      }),

      // Database Checks
      database: () =>
      new Promise<boolean>(async (resolve) => {
        try {
          const result =
            await spocApi.getConfigurationModel();
          const isValid = !!result && typeof result === 'string';

          resolve(isValid);
        } catch (e) {
          resolve(false);
        }
      }),

  };
};
