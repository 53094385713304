import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import React from "react";
import { TELUS_FONT_FAMILY, TELUS_PURPLE } from "../../../../constants/styles";
import { HealthCheckCard } from "./HealthCheckCard";
import { useSPOCHealthChecks } from "./TestFunctions";
import { SectionTitle } from "./SectionTitle";

export const HealthChecksPage = () => {
  const { contentfulAnnouncements, outageBanner, interruptBanner, gpt35, database } =
    useSPOCHealthChecks();

  return (
    <Stack marginTop={4}>
      <Typography
        variant="h4"
        sx={{
          marginBottom: 4,
          fontFamily: TELUS_FONT_FAMILY,
          color: TELUS_PURPLE,
        }}
      >
        Internal <strong>SPOC</strong> health checks
      </Typography>
      <Stack spacing={1}>
      <SectionTitle title="Models" />
        <HealthCheckCard
          checkFunction={gpt35}
          title="GPT 3.5 model"
          description="Check if the GPT 3.5 model is working correctly"
        />
        <HealthCheckCard
          checkFunction={gpt35}
          title="GPT 4o model"
          description="Check if the GPT 4o model is working correctly"
        />
        <SectionTitle title="Chat history" />
        <HealthCheckCard
          checkFunction={async () => {
            return new Promise((resolve) =>
              setTimeout(
                () => resolve(true),
                Math.floor(Math.random() * (2000 - 500 + 1)) + 500
              )
            );
          }}
          title="/getChatHistory endpoint"
          description="Check if the /getChatHistory endpoint is reachable and responding"
        />
        <HealthCheckCard
          checkFunction={async () => {
            return new Promise((resolve) =>
              setTimeout(
                () => resolve(true),
                Math.floor(Math.random() * (2000 - 500 + 1)) + 500
              )
            );
          }}
          title="/deleteChatHistory endpoint"
          description="Check if the /deleteChatHistory endpoint is reachable and responding"
        />
        <SectionTitle title="Contentful" />
        <HealthCheckCard
          checkFunction={contentfulAnnouncements}
          title="Contentful home page announcements"
          description="Check if the Contentful home page announcements are being fetched correctly"
        />
        <HealthCheckCard
          checkFunction={outageBanner}
          title="Contentful outage announcements"
          description="Check if the Contentful outage announcements are being fetched correctly"
        />
        <HealthCheckCard
          checkFunction={interruptBanner}
          title="Contentful interrupt announcements"
          description="Check if the Contentful interrupt announcements are being fetched correctly"
        />
         <SectionTitle title="Database" />
        <HealthCheckCard
          checkFunction={database}
          title="Database connection"
          description="Check if the database connection is working correctly"
        />
      </Stack>
    </Stack>
  );
};
