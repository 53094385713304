import {
  ConfigApi,
  createApiRef,
  DiscoveryApi,
  IdentityApi,
  ProfileInfo,
} from '@backstage/core-plugin-api';
import { FrontendClient } from '@telus/frontend-common';

const CACHE_DURATION_MS = 8 * 60 * 60 * 1000; // 8 hours
const CACHE_PORTFOLIOS_KEY = '@product-inventory-portfolios';
const CACHE_ELEMENTS_DATETIME_KEY = '@product-inventory-elements-datetime';

export interface ProductInventoryServiceApi {
  getProductGroups: () => Promise<ProductGroup[]>;
  getPortfolios: () => Promise<string[]>;
  addProductGroup: (product: ProductGroup) => Promise<ProductGroup>;
  updateProductGroup: (product: ProductGroup) => Promise<ProductGroup>;
  getProfileInfo: () => Promise<ProfileInfo>;
}

export const productInventoryApiRef = createApiRef<ProductInventoryServiceApi>({
  id: 'plugin.product-inventory.service',
});

export type Options = {
  identityApi: IdentityApi;
  discoveryApi: DiscoveryApi;
  configApi: ConfigApi;
};

export class ProductInventoryServiceApiClient extends FrontendClient implements ProductInventoryServiceApi {

  constructor(options: Options) {
    super({
      discoveryApi: options.discoveryApi,
      identityApi: options.identityApi,
      defaultPlugin: 'product-inventory',
    });

  }



  async getProfileInfo(): Promise<ProfileInfo> {
    const profileInfo = await this.identityApi.getProfileInfo();
    return profileInfo;
  }

  async getPortfolios(): Promise<string[]> {
    const cachedData = localStorage.getItem(CACHE_PORTFOLIOS_KEY);
    const cachedTime = localStorage.getItem(CACHE_ELEMENTS_DATETIME_KEY);

    if (cachedData && cachedTime && new Date().getTime() - Number(cachedTime) < CACHE_DURATION_MS) {
      // Data is less than 8 hours old, use the cached version
      return Promise.resolve(JSON.parse(cachedData));
    }
    // Data is old or not existent, fetch again
    await this.getProductGroups();
    const newCachedData = localStorage.getItem(CACHE_PORTFOLIOS_KEY);
    return Promise.resolve(JSON.parse(newCachedData ? newCachedData : '[]'));

  }

  async getProductGroups(): Promise<ProductGroup[]> {
    const resp: ProductGroup[] = await this.getRequired('/productGroup')
    let portfoliosSet = new Set<string>();

    resp.forEach((product: ProductGroup) => {
      if (product.portfolio) {
        portfoliosSet.add(product.portfolio ?? "");
      }
    });
    portfoliosSet = new Set(Array.from(portfoliosSet).sort((a, b) => a.localeCompare(b)));
    this.setPortfolios(Array.from(portfoliosSet));
    localStorage.setItem(CACHE_ELEMENTS_DATETIME_KEY, String(new Date().getTime()));
    return resp;
  }

  private setPortfolios(portfolios: string[]): void {
    localStorage.setItem(CACHE_PORTFOLIOS_KEY, JSON.stringify(portfolios));
  }


  async addProductGroup(product: ProductGroup): Promise<ProductGroup> {
    const profile = this.getProfileInfo();
    product.lastModifiedBy = (await profile).email ?? (await profile).displayName ?? 'unknown';
    const resp: ProductGroup = await this.postRequired('/productGroup', product);
    return resp;
  }

  async updateProductGroup(product: ProductGroup): Promise<ProductGroup> {
    const profile = this.getProfileInfo();
    product.lastModifiedBy = (await profile).email ?? (await profile).displayName ?? 'unknown';
    const resp: ProductGroup = await this.putRequired('/productGroup', product);
    return resp;
  }

}
