import React, { useContext } from 'react';
import { LangContext } from '@telus/frontend-common';

export const DropdownMenu: React.FC<any> = () => {
  const { lang, switchLang } = useContext(LangContext);

  const handleLanguageChange = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    const selectedLanguage = event.target.value;
    if (selectedLanguage === 'en' || selectedLanguage === 'fr') {
      switchLang(selectedLanguage);
    }
  };

  return (
    <select
      value={lang}
      data-testid="language-dropdown"
      onChange={handleLanguageChange}
      style={{
        color: "#606060",
        backgroundColor: "inherit",
        fontSize: "15px",
        border: "none",
        height: "30px",
        marginRight: "16px",
      }}
    >
      <option value="en">EN</option>
      <option value="fr">FR</option>
    </select>
  );
};
