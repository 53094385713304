import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useLocation } from "react-router-dom";
import {
  AppBar,
  Button,
  IconButton,
  Link,
  Tab,
  Tabs,
  makeStyles,
  useMediaQuery,
  useTheme,
  Tooltip,
} from "@material-ui/core";
import simplifyLogo from "./simplifyLogo.png";
import SearchIcon from "@material-ui/icons/Search";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import FeedbackIcon from "@mui/icons-material/Feedback";
import ScienceIcon from "@mui/icons-material/Science";
import { LinkTab } from "./LinkTab";
import { TabPanel } from "./TabPanel";
import { LeftCard } from "./LeftCard";
import { RightCardList } from "./RightCard";
import { SearchContextProvider } from "@backstage/plugin-search-react";
import { useNavigate } from "react-router-dom";
import { MobileNavBar } from "./mobileNav/MobileNavBar";
import { navigateLink } from "./navHelpers";
import { NavSearch } from "./NavSearch";
import { DropdownMenu } from "./DropdownMenu";

import { t } from "./translations";
import { LangContext } from "@telus/frontend-common";
import { NavTabSrcContext, NavTabSrcTypes, getNavTabSrc } from "./types";

const useStyles = makeStyles((theme) => ({
  root: {
    position: "relative",
    zIndex: 10,
    display: "flex",
    flexDirection: "row",
    height: "80px",
    backgroundColor: theme.palette.background.paper,
    top: "0px",
    width: "100%",
    justifyContent: "space-between",
  },
  logo: {
    objectFit: "contain",
    height: "80px",
    cursor: "pointer",
    padding: "5px",
  },
  tab: {
    fontSize: "16px",
    textTransform: "initial",
    "&.Mui-focusVisible": {
      border: `1px solid ${theme.palette.secondary.main}`, // Show when user use tab, and is now on the div that contains the nav tabs
    },
  },
  linkTab: {
    padding: "25px 0",
    fontSize: "16px",
    textTransform: "initial",
    "&.Mui-focusVisible": {
      border: `1px solid ${theme.palette.secondary.main}`, // Show when user use tab, and is now on the div that contains the nav tabs
    },
  },
  tabPanel: {
    position: "absolute",
    top: "80px",
    width: "75%",
    backgroundColor: theme.palette.background.default,
    zIndex: 10,
    boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
  },
  navButton: {
    alignSelf: "center",
    margin: "16px",
    textTransform: "initial",
    padding: "4px 8px",
    fontSize: "16px",
  },
  searchBar: {
    padding: "16px",
  },
  searchSubmitIcon: {
    fontSize: "36px",
    color: theme.palette.grey[700],
    position: "absolute",
    right: "80px",
    top: "24px",
    borderRadius: "50%",
    cursor: "pointer",
    padding: "4px",
    "&:hover": {
      backgroundColor: theme.palette.secondary.light,
      color: theme.palette.background.default,
    },
    "&:focus": {
      backgroundColor: theme.palette.background.default,
      color: theme.palette.secondary.main,
      border: "3px solid",
    },
  },
  expandIconOpen: {
    paddingTop: "4px",
    marginBottom: "-5px",
    transform: "rotate(0.5turn)",
  },
  expandIconClosed: {
    paddingTop: "4px",
    marginBottom: "-5px",
  },
  searchToggleIcon: {
    alignSelf: "center",
    fontSize: "48px",
    margin: "4px",
    color: theme.palette.grey[700],
    borderRadius: "50%",
    cursor: "pointer",
    padding: "8px",
    "&:hover": {
      backgroundColor: theme.palette.secondary.light,
      color: theme.palette.background.default,
    },
    "&:focus": {
      backgroundColor: theme.palette.background.default,
      color: theme.palette.secondary.main,
      border: "3px solid",
    },
  },
}));

function a11yProps(index: any) {
  return {
    id: `tab-${index}`,
    "aria-controls": `tabpanel-${index}`,
  };
}

export type DesktopContext = {
  value: number | boolean;
  setValue: (v: boolean | number) => void;
};

export const DesktopDropdownContext = createContext<DesktopContext>({
  value: false,
  setValue: () => {},
});

export const useDesktopContext = () => useContext(DesktopDropdownContext);

export const NavBar = () => {
  const classes = useStyles();
  const [value, setValue] = useState<boolean | number>(0);
  const [showSearch, setShowSearch] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const isGptPage =
    location.pathname.includes("/gpt") && !location.pathname.endsWith("/lab");
  const showTryGenAI = isGptPage ? "none" : "flex";
  const theme = useTheme();
  const mobileView = useMediaQuery(theme.breakpoints.down("md"));
  const { lang } = useContext(LangContext);
  const [navTabSrc, setNavTabSrc] = useState<NavTabSrcTypes>(
    getNavTabSrc(lang)
  );

  useEffect(() => {
    setNavTabSrc(getNavTabSrc(lang));
  }, [lang]);

  const memo = useMemo(() => {
    return navTabSrc;
  }, [navTabSrc]);

  const dropdownOpen = (tabNum: number | boolean) => {
    if (value === tabNum) {
      return true;
    }
    return false;
  };

  const handleChange = (
    event: React.ChangeEvent<{}>,
    newValue: number | boolean
  ) => {
    event.preventDefault();
    if (dropdownOpen(newValue) && (newValue === 1 || newValue === 2)) {
      setValue(false);
    } else {
      setValue(newValue);
    }
  };

  if (mobileView) {
    if (value) {
      setValue(false);
    }
  }

  const handleClick = () => {
    setShowSearch(true);
  };

  return (
    <SearchContextProvider>
      <NavTabSrcContext.Provider value={memo}>
        <DesktopDropdownContext.Provider value={{ value, setValue }}>
          {mobileView ? (
            <MobileNavBar />
          ) : (
            <AppBar className={classes.root} position="static" color="inherit">
              {showSearch ? (
                <div style={{ display: "flex", width: "100%" }}>
                  <Link
                    component="a"
                    href="/"
                    onClick={navigateLink(setValue, navigate)}
                    tabIndex={0}
                  >
                    <img
                      className={classes.logo}
                      src={simplifyLogo}
                      alt="TELUS | Simplify Hub"
                    />
                  </Link>
                  <NavSearch
                    id="nav-search"
                    className={classes.searchBar}
                    onClear={() => setShowSearch(false)}
                  />
                </div>
              ) : (
                <div
                  style={{
                    display: "flex",
                    width: "100%",
                    justifyContent: "space-between",
                  }}
                >
                  <div style={{ display: "flex" }}>
                    <Link
                      component="a"
                      href="/"
                      onClick={navigateLink(setValue, navigate)}
                    >
                      <img
                        className={classes.logo}
                        src={simplifyLogo}
                        alt="TELUS | Simplify Hub"
                      />
                    </Link>
                    <Tabs
                      value={value}
                      onChange={handleChange}
                      variant="fullWidth"
                      aria-label="Simplify Hub Navigation Bar"
                    >
                      <LinkTab
                        label={navTabSrc.Home.label}
                        href={navTabSrc.Home.href}
                        className={classes.linkTab}
                        tabIndex={0}
                        {...a11yProps(0)}
                      />
                      <Tab
                        label={
                          <div style={{whiteSpace: 'nowrap'}}>
                            {navTabSrc.Catalog.label}
                            <ExpandMoreIcon
                              className={
                                dropdownOpen(1)
                                  ? classes.expandIconOpen
                                  : classes.expandIconClosed
                              }
                            />
                          </div>
                        }
                        className={classes.tab}
                        tabIndex={0}
                        {...a11yProps(1)}
                        disableRipple
                        disableTouchRipple
                        disableFocusRipple
                      />
                      <Tab
                        label={
                          <div style={{whiteSpace: 'nowrap'}}>
                            {navTabSrc.Applications.label}
                            <ExpandMoreIcon
                              className={
                                dropdownOpen(2)
                                  ? classes.expandIconOpen
                                  : classes.expandIconClosed
                              }
                            />
                          </div>
                        }
                        className={classes.tab}
                        tabIndex={0}
                        {...a11yProps(2)}
                        disableRipple
                        disableTouchRipple
                        disableFocusRipple
                      />
                      <LinkTab
                        label={navTabSrc.Resources.label}
                        href={navTabSrc.Resources.href}
                        className={classes.linkTab}
                        tabIndex={0}
                        {...a11yProps(3)}
                      />
                      <LinkTab
                        label={navTabSrc.Settings.label}
                        href={navTabSrc.Settings.href}
                        className={classes.linkTab}
                        tabIndex={0}
                        {...a11yProps(4)}
                      />
                    </Tabs>
                  </div>

                  <div style={{ display: "flex" }}>
                    <div
                      style={{
                        display: "flex",
                        height: "100%",
                        alignItems: "center",
                      }}
                    >
                      <DropdownMenu />
                      {isGptPage && (
                        <div>
                          <Tooltip title={t.feedback[lang]} arrow>
                            <IconButton
                              aria-label="Provide feedback on experience"
                              className={classes.searchToggleIcon}
                              onClick={() =>{

                                if(location.pathname === "/gpt/copilots/spoc"){
                                  window.open(
                                    "https://forms.monday.com/forms/07fd557cadaf6abe561cc010535d8e57?r=use1"
                                  )
                                }else {
                                  window.open(
                                    "https://docs.google.com/forms/d/e/1FAIpQLScqd9Leq5nXEMz4Q17YSDcpPmICZII2HiPmpuahictXk_IE_Q/viewform"
                                  )

                                }

                              }
                              }
                              tabIndex={0}
                              style={{ marginRight: "12px" }}
                            >
                              <FeedbackIcon />
                            </IconButton>
                          </Tooltip>
                          <Tooltip title={t.lab[lang]} arrow>
                            <IconButton
                              aria-label="Visit the GPT lab page"
                              className={classes.searchToggleIcon}
                              href="/gpt/lab"
                              tabIndex={0}
                              style={{ marginRight: "12px" }}
                            >
                              <ScienceIcon />
                            </IconButton>
                          </Tooltip>
                        </div>
                      )}
                    </div>
                    <IconButton
                      aria-label="Search"
                      className={classes.searchToggleIcon}
                      onClick={handleClick}
                      tabIndex={0}
                      // style={{ marginRight: "16px" }}
                    >
                      <SearchIcon />
                    </IconButton>
                    <Button
                      size="small"
                      component="a"
                      style={{ display: showTryGenAI }}
                      className={classes.navButton}
                      color="primary"
                      variant="outlined"
                      href="/gpt"
                      onClick={navigateLink(setValue, navigate)}
                      tabIndex={0}
                    >
                      {t.tryGenAILaunchpad[lang]}
                    </Button>
                  </div>
                </div>
              )}
            </AppBar>
          )}

          <TabPanel className={classes.tabPanel} value={value} index={1}>
            <LeftCard
              title={navTabSrc.Catalog.tabPanel?.leftCard.title}
              type="Catalog"
            />
            <RightCardList type="Catalog" />
          </TabPanel>
          <TabPanel className={classes.tabPanel} value={value} index={2}>
            <LeftCard
              title={navTabSrc.Applications.tabPanel?.leftCard.title}
              type="Applications"
            />
            <RightCardList type="Applications" />
          </TabPanel>
        </DesktopDropdownContext.Provider>
      </NavTabSrcContext.Provider>
    </SearchContextProvider>
  );
};
