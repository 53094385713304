import React from "react";
import {
  TELUS_ALERT_RED,
  TELUS_BORDER_COLOR,
  TELUS_CARD_BACKGROUND,
  TELUS_GREEN,
} from "../../../../constants/styles";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import Skeleton from "@mui/material/Skeleton";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";

type HealthCheckCardProps = {
  checkFunction: () => Promise<boolean>;
  title: string;
  description: string;
};

const cardStyles = {
  borderRadius: 3,
  padding: 2,
  border: `1px solid ${TELUS_BORDER_COLOR}`,
  backgroundColor: TELUS_CARD_BACKGROUND,
};

export const HealthCheckCard: React.FC<HealthCheckCardProps> = ({
  checkFunction,
  title,
  description,
}) => {
  const [status, setStatus] = React.useState<boolean>(false);
  const [loading, setLoading] = React.useState<boolean>(false);

  React.useEffect(() => {
    const check = async () => {
      try {
        setLoading(true);
        const result = await checkFunction();
        setStatus(result);
      } catch (error) {
        setStatus(false);
      } finally {
        setLoading(false);
      }
    };

    check();
  }, [checkFunction]);

  if (loading)
    return (
      <Box
        sx={{
          ...cardStyles,
          width: "100%",
        }}
      >
        <Stack direction="row">
          <Stack flex={1}>
            <Skeleton width={500} height="2.2em" />
            <Skeleton width="90%" />
          </Stack>
          <CircularProgress color="secondary" />
        </Stack>
      </Box>
    );

  return (
    <Box
      sx={{
        ...cardStyles,
        width: "100%",
      }}
    >
      <Stack direction="row" alignItems="center">
        <Stack flex={1}>
          <Typography variant="h6">{title}</Typography>
          <Typography>{description}</Typography>
        </Stack>
        {status ? (
          <CheckCircleIcon sx={{ color: TELUS_GREEN, fontSize: 40 }} />
        ) : (
          <CancelIcon sx={{ color: TELUS_ALERT_RED, fontSize: 40 }} />
        )}
      </Stack>
    </Box>
  );
};
