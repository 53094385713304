import { createRouteRef, createSubRouteRef } from '@backstage/core-plugin-api';

export const rootRouteRef = createRouteRef({
  id: 'product-inventory',
});


// @see: https://backstage.io/docs/plugins/composability#subroutes
export const productCreateRouteRef = createSubRouteRef({
  id: 'product-inventory-create',
  parent: rootRouteRef,
  path: '/create',
});
