import React from "react";
import { Route } from "react-router";
import { apiDocsPlugin, ApiExplorerPage } from "@backstage/plugin-api-docs";
import {
  CatalogEntityPage,
  CatalogIndexPage,
  catalogPlugin,
} from "@backstage/plugin-catalog";
import {
  CatalogImportPage,
  catalogImportPlugin,
} from "@backstage/plugin-catalog-import";
import { ScaffolderPage, scaffolderPlugin } from "@backstage/plugin-scaffolder";
import { orgPlugin } from "@backstage/plugin-org";
import { SearchPage } from "@backstage/plugin-search";
import {
  // TechDocsIndexPage,
  techdocsPlugin,
  // TechDocsReaderPage,
} from "@backstage/plugin-techdocs";
// import { TechDocsAddons } from '@backstage/plugin-techdocs-react';
// import { ReportIssue } from '@backstage/plugin-techdocs-module-addons-contrib';
import { UserSettingsPage } from "@backstage/plugin-user-settings";
import { apis } from "./apis";
import { entityPage } from "./components/catalog/EntityPage";
import { searchPage } from "./components/search/SearchPage";
import { Root } from "./components/Root";
import {
  AlertDisplay,
  OAuthRequestDialog,
  AutoLogout
} from "@backstage/core-components";
import { createApp } from "@backstage/app-defaults";
import { FlatRoutes } from "@backstage/core-app-api";
import { CatalogGraphPage } from "@backstage/plugin-catalog-graph";
import { ExternalLinksPage } from "./components/external/ExternalLinksPage";
import { RequirePermission } from "@backstage/plugin-permission-react";
import { catalogEntityCreatePermission } from "@backstage/plugin-catalog-common/alpha";
import { HomepageCompositionRoot } from "@backstage/plugin-home";
import { HomePage } from "./components/home/HomePage";
import CssBaseline from "@material-ui/core/CssBaseline";
import { ThemeProvider } from "@material-ui/core/styles";
import { telusTheme } from "./theme";
import {
  // githubAuthApiRef,
  googleAuthApiRef,
  discoveryApiRef,
  useApi,
  IdentityApi,
} from "@backstage/core-plugin-api";
import { SignInProviderConfig } from "@backstage/core-components";
import { AlliumProvider } from "@telus-uds/ds-allium";
import { ExternalHealthCheckPage } from "@telus/plugin-external-health-check-frontend";
import { badgesPlugin } from "@backstage/plugin-badges";
import { MkdocsCreatorPage } from "@telus/plugin-mkdocs-creator";
import { TeamSkillsPage } from "@telus/plugin-team-skills-frontend";
import { ApiToolsPage } from "@telus/plugin-api-tools";
import { ErrorMessagePage } from "@telus/plugin-error-page";
import { QetaPage } from "@drodil/backstage-plugin-qeta";
import { DynatracePage } from "@telus/plugin-dynatrace";
import { SignInPage } from "./components/sign-in/SignIn";
import { SecurityMetricsPage } from "@telus/plugin-security-metrics";
import { TascFrontendPage } from "@telus/plugin-tasc-frontend";
import {
  GptContainerPage,
  GptImageGenPage,
  GptLabPage,
} from "@telus/plugin-gpt";
import { TdrmPage } from "@telus/plugin-tdrm";
import {
  DocsViewerDocumentPage,
  DocsViewerIndexPage,
  DocsViewerHtmlPage,
} from '@telus/plugin-docs-viewer-frontend';
import { CcoeFrontendPage } from '@telus/ccoe-onboard-plugin-frontend';
import { CcoeServiceAccountKeyPluginFrontendPage } from '@telus/ccoe-service-account-key-plugin-frontend/';
import { BillingChargeManagementPluginPage } from '@telus/ccoe-billing-charge-management-frontend';
import { CcoeNamespaceGeneratorFrontendPage } from '@telus/ccoe-namespace-generator-frontend';
import { CcoeGroupsManagementFrontendPage } from '@telus/ccoe-groups-management-frontend';
import { CostOptimizationsFrontendPage } from '@telus/ccoe-cost-optimization-frontend';
import { UnicornAiProxyKeyMgmtFrontendPage } from "@telus/unicorn-ai-proxy-key-mgmt-frontend"
import { TdAcademyFrontendMainPage } from '@telus/plugin-td-academy-frontend';
import { HealthChecksPage, SpocContainerPage } from '@telus/plugin-spoc';
import { IccFrontendPage } from '@telus/plugin-icc-frontend';
import { LangProvider } from "@telus/frontend-common";
import { LbRequestFrontendPage } from "@telus/plugin-lb-request-frontend";
import { AacTemplateLandingPage } from '@telus/plugin-aac-template-landing';
import { WaskPage, MfeLitePage } from '@telus/plugin-wask';
import { AMPPage } from '@telus/api-marketplace-frontend-plugin';
import { useViewportHeight } from "./useViewportHeight";
// import { BrcLoginPage } from '@telus/plugin-brc-login';
import { ProductInventoryPage } from '@telus/plugin-product-inventory';
import Container from "@mui/material/Container";

// Parses supplied JWT token and returns the payload
function parseJwt(token: string): { exp: number } {
  const base64Url = token.split(".")[1];
  const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
  const jsonPayload = decodeURIComponent(
    atob(base64)
      .split("")
      .map(function concat(c) {
        const code = c.charCodeAt(0).toString(16);
        const finalConcat = `00${code}`.slice(-2);
        return `%${finalConcat}`;
      })
      .join("")
  );

  return JSON.parse(jsonPayload);
}

// Returns milliseconds until the supplied JWT token expires
function msUntilExpiry(token: string): number {
  const payload = parseJwt(token);
  const remaining =
    new Date(payload.exp * 1000).getTime() - new Date().getTime();
  return remaining;
}

// Calls the specified url regularly using an auth token to set a token cookie
// to authorize regular HTTP requests when loading techdocs
async function setTokenCookie(url: string, identityApi: IdentityApi) {
  const { token } = await identityApi.getCredentials();
  if (!token) {
    return;
  }

  await fetch(url, {
    mode: "cors",
    credentials: "include",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  // Call this function again a few minutes before the token expires
  const ms = msUntilExpiry(token) - 4 * 60 * 1000;
  setTimeout(
    () => {
      setTokenCookie(url, identityApi);
    },
    ms > 0 ? ms : 10000
  );
}

// commented due to access restriction to specific features of plugins
// const githubProvider: SignInProviderConfig = {
//   id: "github-auth-provider",
//   title: "GithubTitle",
//   message: "GithubDescription",
//   apiRef: githubAuthApiRef,
// };

const googleProvider: SignInProviderConfig = {
  id: "google-auth-provider",
  title: "GoogleTitle",
  message: "GoogleDescription",
  apiRef: googleAuthApiRef,
};

const app = createApp({
  apis,
  plugins: [badgesPlugin],
  featureFlags: [
    {
      pluginId: "gpt",
      name: "gpt4-access",
      description: "Enables use of GPT4 in the GenAI tool",
    },
    {
      pluginId: "gpt",
      name: "debug-mode",
      description: "Enables debug mode for unicorn endpoint",
    },
    {
      pluginId: "gpt",
      name: "explore",
      description: "Enables explore mode for unicorn endpoint"
    },
    {
      pluginId: "gpt",
      name: "disable-sound-notification",
      description: "Disables sound notification when an Agent joins chat in SPOC experience"
    },
    {
      pluginId: "gpt",
      name: "enable-incognito-mode",
      description: "Enables the incognito mode feature"
    },
    {
      pluginId: "gpt",
      name: "spoc-custom-responses",
      description: "Enables SPOC custom responses feature"
    }
  ],
  components: {
    SignInPage: (props) => {
      const discoveryApi = useApi(discoveryApiRef);
      return (
        <SignInPage
          {...props}
          providers={[googleProvider]} // githubProvider // commented due to access restriction to specific features of plugins
          align="center"
          onSignInSuccess={async (identityApi: IdentityApi) => {
            setTokenCookie(
              await discoveryApi.getBaseUrl("cookie"),
              identityApi
            );
            props.onSignInSuccess(identityApi);
          }}
        />
      );
    },
  },
  bindRoutes({ bind }) {
    bind(catalogPlugin.externalRoutes, {
      createComponent: scaffolderPlugin.routes.root,
      viewTechDoc: techdocsPlugin.routes.docRoot,
      createFromTemplate: scaffolderPlugin.routes.selectedTemplate,
    });
    bind(apiDocsPlugin.externalRoutes, {
      registerApi: catalogImportPlugin.routes.importPage,
    });
    bind(scaffolderPlugin.externalRoutes, {
      registerComponent: catalogImportPlugin.routes.importPage,
      viewTechDoc: techdocsPlugin.routes.docRoot,
    });
    bind(orgPlugin.externalRoutes, {
      catalogIndex: catalogPlugin.routes.catalogIndex,
    });
  },
  themes: [
    {
      id: "telus-theme",
      title: "TELUS",
      variant: "light",
      Provider: ({ children }) => (
        <ThemeProvider theme={telusTheme}>
          <CssBaseline>{children}</CssBaseline>
        </ThemeProvider>
      ),
    },
  ],
});

const AppProvider = app.getProvider();
const AppRouter = app.getRouter();

const routes = (
  <FlatRoutes>
    <Route path="/" element={<HomepageCompositionRoot />}>
      <HomePage />
    </Route>
    <Route
      path="/catalog"
      element={<CatalogIndexPage initiallySelectedFilter="all" />}
    />
    <Route
      path="/catalog/:namespace/:kind/:name"
      element={<CatalogEntityPage />}
    >
      {entityPage}
    </Route>
    {/* <Route path="/docs" element={<TechDocsIndexPage />} /> */}
    {/* <Route path="/docs/:namespace/:kind/:name" element={<TechDocsReaderPage />}>
      <TechDocsAddons>
        <ReportIssue />
      </TechDocsAddons>
    </Route> */}
    <Route path="/spoc" element={<SpocContainerPage />} />
    <Route path="/spoc/health-checks" element={<Container><HealthChecksPage /></Container>} />
    <Route path="/universal-design-system" element={<DocsViewerHtmlPage />} />
    <Route path="/uds" element={<DocsViewerHtmlPage />} />
    <Route path="/unicorn-platform" element={<DocsViewerHtmlPage />} />
    <Route path="/platform-engineering" element={<DocsViewerHtmlPage />} />
    <Route path="/docs" element={<DocsViewerIndexPage />} />
    <Route path="/docs/:reponame/*" element={<DocsViewerDocumentPage />} />
    <Route path="/create" element={<ScaffolderPage />} />
    <Route path="/api-docs" element={<ApiExplorerPage />} />
    <Route
      path="/catalog-import"
      element={
        <RequirePermission permission={catalogEntityCreatePermission}>
          <CatalogImportPage />
        </RequirePermission>
      }
    />

    <Route path="/search" element={<SearchPage />}>
      {searchPage}
    </Route>
    <Route path="/settings" element={<UserSettingsPage data-di-mask="" />} />
    <Route
      path="/catalog-graph"
      element={<CatalogGraphPage initialState={{ maxDepth: 2 }} />}
    />
    <Route path="/external" element={<ExternalLinksPage />} />
    <Route
      path="/external-health-check"
      element={<ExternalHealthCheckPage />}
    />
    <Route path="/api-tools" element={<ApiToolsPage />} />
    <Route path="/mkdocs-creator" element={<MkdocsCreatorPage />} />
    <Route path="/lb-request" element={<LbRequestFrontendPage />} />
    <Route path="/cio-tec-mentorship/*" element={<TeamSkillsPage />} />
    <Route path="/qeta" element={<QetaPage title="Questions" />} />
    <Route path="/dynatrace-monitor" element={<DynatracePage />} />
    <Route path="/gpt" element={<GptContainerPage />} />
    <Route path="/gpt/image-gen" element={<GptImageGenPage />} />
    <Route path="/gpt/lab" element={<GptLabPage />} />
    <Route path="/tdrm" element={<TdrmPage />} />
    <Route path="/security-metrics" element={<SecurityMetricsPage />} />
    <Route path="/*" element={<ErrorMessagePage />} />
    <Route path="/cloud-management" element={<CcoeFrontendPage />} />
    <Route
      path="/cloud-management/service-account-key/*"
      element={<CcoeServiceAccountKeyPluginFrontendPage />}
    />
    <Route
      path="/cloud-management/namespace"
      element={<CcoeNamespaceGeneratorFrontendPage />}
    />
    <Route
      path="/cloud-management/groups-management/*"
      element={<CcoeGroupsManagementFrontendPage />}
    />
    <Route
      path="/cloud-management/cost-optimization-gatekeeper"
      element={<CostOptimizationsFrontendPage />}
    />
    <Route
      path="/cloud-management/billing-charge-management"
      element={<BillingChargeManagementPluginPage />}
    />
    <Route path="/unicorn-ai-proxy-key-mgmt" element={<UnicornAiProxyKeyMgmtFrontendPage />} />
    <Route path="/td-academy" element={<TdAcademyFrontendMainPage />} />
    <Route path="/icc" element={<IccFrontendPage />} />
    <Route path="/video-library" element={<TdAcademyFrontendMainPage />} />
    <Route path="/aac-template-landing" element={<AacTemplateLandingPage />} />
    <Route path="/wask" element={<WaskPage />} />
    <Route path="/mfe-lite" element={<MfeLitePage />} />
    {/* <Route path="/brc-login" element={<BrcLoginPage />} /> */}
    <Route path="/api-marketplace" element={<AMPPage />} />
    <Route path="/product-inventory" element={<ProductInventoryPage />} />
    <Route path="/tasc" element={<TascFrontendPage />} />

  </FlatRoutes>
);

const App = () => {
  const vh = useViewportHeight();
  return (


  <>
    <AlliumProvider>
      <AppProvider>
        <AlertDisplay />
        <OAuthRequestDialog />
        <AutoLogout
          idleTimeoutMinutes={720}
          useWorkerTimers={false}
          promptBeforeIdleSeconds={30}
        />
        <AppRouter>
          <LangProvider>
            <div style={{ height: `${vh * 100}px`, maxHeight: `${vh * 100}px` }}>
              <Root>{routes}</Root>
            </div>
          </LangProvider>
        </AppRouter>
      </AppProvider>
    </AlliumProvider>
  </>
)};

export default App;
