import {
  configApiRef,
  createApiFactory,
  createPlugin,
  createRoutableExtension,
  discoveryApiRef,
  identityApiRef,

} from '@backstage/core-plugin-api';

import { rootRouteRef, productCreateRouteRef } from './routes';
import { productInventoryApiRef, ProductInventoryServiceApiClient } from './api/product-inventory-service';

export const productInventoryPlugin = createPlugin({
  id: 'product-inventory',
  apis: [
    createApiFactory({
      api: productInventoryApiRef,
      deps: {
        discoveryApi: discoveryApiRef,
        identityApi: identityApiRef,
        configApi: configApiRef,
      },
      factory: (deps) =>
        new ProductInventoryServiceApiClient(deps),
    }),
  ],
  routes: {
    root: rootRouteRef,
    productCreate: productCreateRouteRef,
  },
  featureFlags: [
    {
      name: 'edit-cio-product-inventory',
    },
  ]
});

export const ProductInventoryPage = productInventoryPlugin.provide(
  createRoutableExtension({
    name: 'ProductInventoryPage',
    component: () =>
      import('./components/index').then(m => m.ProductPluginLayout),
    mountPoint: rootRouteRef,
  }),
);



